<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <DateRangePicker
              :dateFrom.sync="dateFrom"
              :date-to.sync="dateTo"
              @onChangeDate="onChangeDate"
          ></DateRangePicker>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="orderCode"
              v-model="orderCode"
              :placeholder="$t('Transaction.OrderAndRequestCode')"
              v-on:keyup.enter="search"
          >
            <a-icon slot="suffix" type="search" />
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="cardNumber"
              v-model="cardNumber"
              :placeholder="$t('Transaction.CardNumber')"
              v-on:keyup.enter="search"
          >
            <a-icon slot="suffix" type="search" />
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="channel"
              v-model="channel"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="dollar" />
            <a-select-option value="">{{ $t('Transaction.Channel') }}</a-select-option>
            <a-select-option
                v-for="(channelName, id) in showAllChannel()"
                :key="id"
                :value="id"
            >
              {{ $t(channelName) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="status"
              v-model="status"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="file-protect" />
            <a-select-option value="">{{ $t('Transaction.Status') }}</a-select-option>
            <a-select-option
                v-for="(item, id) in getAllTransactionStatus()"
                :key="id"
                :value="item.value"
            >
              {{ $t(item.text) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="method"
              v-model="method"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="dollar" />
            <a-select-option value="">{{ $t('Transaction.Method') }}</a-select-option>
            <a-select-option
                v-for="(methodName, id) in showAllTransactionMethod()"
                :key="id"
                :value="id"
            >
              {{ $t(methodName) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="type"
              v-model="type"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="menu" />
            <a-select-option value="">{{ $t('Transaction.Type') }}</a-select-option>
            <a-select-option
                v-for="(typeName, id) in getAllTransactionType()"
                :key="id"
                :value="id"
            >
              {{ $t(typeName) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col
          :xs="{ span: 24 }"
          :xl="{ span: 6 }"
          class="pr-10 pl-10 mb-3 my-xl-2"
          v-if="isNaN(merchants)">
          <a-select
            style="width: 100%"
            @change="selectMerchant"
            default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2" v-if="user && (user.type != UserTypeConstant.MASTER_MERCHANT)">
            <a-select
              ref="profileId"
              v-model="profileId"
              default-value=""
              class="w-100"
              :placeholder="getPlaceholderText()"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option value="">
                {{ $t(TransactionColumnConstant.FILTER_OF_PROFILE_PLACEHOLDER) }}
              </a-select-option>
              <a-select-option
                  v-for="(profile) in profileOptions"
                  :key="profile.id"
                  :value="profile.id"
              >
                {{ $t(profile.label) }}
              </a-select-option>
            </a-select>
          </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2"
               v-if="user
                && ((user.type != UserTypeConstant.MASTER_MERCHANT) && checkMerchantActive(userInfo))">
          <a-select
              ref="updatedBy"
              v-model="updatedBy"
              default-value=""
              class="w-100"
              show-search
              :filter-option="filterOptionUpdatedBy">
            <a-select-option value="">
              {{ $t('Transaction.SelectCreator') }}
            </a-select-option>
            <a-select-option
                v-for="(member, index) in members"
                :key="index"
                :value="member"
            >
              {{ $t(member) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div>
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" class="me-2" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
        <a-button type="link" @click="showModalStatus">
          {{ $t('Label.LearnMoreStatus')}}
        </a-button>

        <a-modal
          :title="$t('Label.TransactionStatus')"
          v-model="visibleStatus"
          @ok="handleOkStatus"
          wrapClassName="modal-status"
          :okText="$t('Button.Close')"
        >
          <a-table bordered :columns="columnsStatus" :data-source="dataStatus" :pagination="false">
            <template #customTitleStatusNo>{{ $t('Transaction.No') }}</template>

            <template #customTitleStatusStatus>{{ $t('Transaction.Status') }}</template>
            <template slot="status" slot-scope="text, record">{{ $t(record.status) }}</template>

            <template #customTitleStatusExplain>{{ $t('Label.Explain') }}</template>
            <template slot="explain" slot-scope="text, record">{{ $t(record.explain) }}</template>
          </a-table>
        </a-modal>
      <a-row>

      </a-row>
    </a-card>
    <a-card class="mb-30 shadow">
      <div class="row">
        <div class="col-12 col-md-6">
          <p>{{ $t('Transaction.TotalTransaction') }} : {{ showMoneyFormat(totalRow) }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>{{ $t('Transaction.TotalFeeTransaction')}} : {{ showMoneyFormat(totalInfo.totalFee) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <p>{{ $t('Transaction.TotalAmountTransaction') }} : {{ showMoneyFormat(totalInfo.totalSuccess) }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>{{ $t('Transaction.TotalAmountReceived')}} : {{ showMoneyFormat(totalInfo.totalReceivedAmount) }}</p>
        </div>
      </div>
    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-row>
          <a-col :xs="{ span: 24 }" :xl="{ span: 15 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-dropdown style="width: 300px; display: flex; justify-content: space-between; align-items: center;" v-model="visible">
              <template #overlay>
                <a-menu>
                  <a-menu-item class="all" @change="selectAll">
                    <a-checkbox v-model="allSelected" value="all">{{ $t('Transaction.SelectAll') }}</a-checkbox>
                  </a-menu-item>
                  <a-menu-item :key="id" v-for="(column, id) in TransactionColumnConstant.TRANSACTION_COLUMN" :class="column.class" @change="handleChooseColumn(column.class)">
                    <a-checkbox :checked="selected.includes(column.class)" :value="column.class">{{ $t(column.title) }}</a-checkbox>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button @click="handleOpenMenu">
                {{ $t('Transaction.ChooseColumn') }} ({{ selected.length }})
                <i class="bi bi-chevron-down ms-auto"></i>
              </a-button>
            </a-dropdown>
          </a-col>
          <a-col :xs="{ span: 24 }" :xl="{ span: 9 }" class="">
              <a-button type="primary" class="float-end btn-export" @click="exportData" :loading="downloading" icon="export">
                {{ $t('Button.Export') }}
              </a-button>
          </a-col>
        </a-row>

        <a-table
          :data-source="transactions"
          :columns="columns"
          :row-key="record => record.id"
          :pagination="false"
          class="mb-30 nowrap"
          :scroll="{x: 'auto'}"
        >
          <template #customTitleNo>
            <div class="no" >
              <p align="center" class="mb-0">{{ $t('Transaction.No') }}</p>
            </div>
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold" class="mb-0">{{ (currentPage - 1) * perPage + index + 1 }}</p>
          </template>

          <template #customTitleCreatedAt>
            <div class="created-time">
              <p align="center" class="mb-0">{{ $t('Transaction.CreatedAt') }}</p>
            </div>
          </template>
          <template slot="created_at" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}</p>
          </template>

          <template #customTitleUpdatedAt>
            <div class="updated-time">
              <p align="center" class="mb-0">{{ $t('Transaction.UpdatedAt') }}</p>
            </div>
          </template>
          <template slot="updated_at" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showFormatDate(record.updated_at, "HH:mm:ss DD/MM/YYYY") }}</p>
          </template>

          <template #customTitleMerchant v-if="isNaN(merchants)">
            <div class="merchant-name">
              <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
            </div>
          </template>
          <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchants)">
            <p align="center" class="mb-0">{{ merchants[record.merchant_id].name }}</p>
          </template>

          <template #customTitleOrderCode>
            <div class="order-code">
              <p align="center" class="mb-0">{{ $t('Transaction.OrderCode') }}</p>
            </div>
          </template>
          <template slot="order_code" slot-scope="text, record">
            <router-link
              :to="{ name: 'TransactionDetail', params: {order_code: record.order_code} }"
              v-if="checkPermission(user, 'canViewDetailTransaction')"
            >
              {{ record.order_code }}
            </router-link>
            <p class="mb-0" v-else>{{ record.order_code }}</p>
            <p class="mb-0" v-if="record.refund_amount"><img src="/assets/img/icon/icon_refund.svg"/></p>
          </template>

          <template #customTitleRequestId>
            <p align="center" class="mb-0">{{ $t('Transaction.RequestAndContractCode') }}</p>
          </template>
          <template slot="request_id" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">
              {{ record.transaction_type != TransactionTypeConstant.REFUND
                ? record.request_id
                : (isset(record.order_refund) && isset(record.order_refund.request_id))
                  ? record.order_refund.request_id
                  : (isset(record.refund) && isset(record.refund.reference_code) ? record.refund.reference_code : '') }}
            </p>
          </template>

  <!--        <template #customTitleFeePayer>-->
  <!--          <div class="text-nowrap text-end">{{ $t('Label.FeePayerTitle') }}</div>-->
  <!--        </template>-->

  <!--        <template slot="partner_fee" slot-scope="text, record">-->
  <!--          <div class="text-start">{{ $t(showFeePayerTransactions(record.partner_fee)) }}</div>-->
  <!--        </template>-->

          <template #customTitleChannel>
            <div class="channel">
              <p align="center" class="mb-0">{{ $t('Transaction.Channel') }}</p>
            </div>
          </template>
          <template slot="channel" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">{{ $t(showChannel(record.channel)) }}</p>
          </template>

          <template #customTitleProfile>
            <div class="profile-id">
              <p align="center" class="mb-0">Profile</p>
            </div>
          </template>
          <template slot="profile_id" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">{{ isset(record.order) && record.order.profile_id > 0 ? profileInfo[record.order.profile_id] : '--' }}</p>
          </template>

          <template #customTitleTransactionType>
            <div class="transaction-type">
              <p align="center" class="mb-0">{{ $t('Transaction.TypeTh') }}</p>
            </div>
          </template>
          <template slot="transaction_type" slot-scope="text, record">
            <span align="center" v-html="getTransactionTypeHtml(record.transaction_type, $t)"></span>
          </template>

          <template #customTitleMethod>
            <div class="payment-method">
              <p align="center" class="mb-0">{{ $t('Transaction.MethodTh') }}</p>
            </div>
          </template>
          <template slot="method" slot-scope="text, record" align="center">
            {{ $t(showAllTransactionMethod()[record.method]) }}
          </template>

          <template #customTitleUpdatedBy>
            <p align="center" class="mb-0">{{ $t('Transaction.UpdatedBy') }}</p>
          </template>
          <template slot="updated_by" slot-scope="text, record">
            <p class="mb-0" align="center">
              {{ isset(record.updated_by) ? record.updated_by : '--' }}
            </p>
          </template>

          <template #customTitleBankAndCardNumber>
            <div class="bank">
              <p align="center" class="mb-0">{{ $t('Transaction.BankAndCardNumber') }}</p>
            </div>
          </template>
          <template slot="card_number" slot-scope="text, record">
            <p align="center" class="mb-0" v-if="record.card_number != '' && record.card_number != null">
              {{ record.bank_code }}
              <br>
              {{ record.card_number }}
            </p>
          </template>

          <template #customTitleAmount>
            <div class="transaction-amount">
              <p align="center" class="mb-0">{{ $t('Transaction.Amount') }}</p>
            </div>
          </template>
          <template slot="amount" slot-scope="text, record">
            {{ showMoneyFormat(record.amount) }}
            {{ record.currency }}
          </template>

          <template #customTitleReferenceValueAndRateVND>
            <div class="transaction-reference-value">
              <p align="center" class="mb-0">{{ $t('Transaction.ReferenceValueAndRateVND') }}</p>
            </div>
          </template>
          <template slot="foreign_amount" slot-scope="text, record" align="center">
            <p align="center" class="mb-0" v-if="record.foreign_amount != null">
              {{ showMoneyFormat(record.foreign_amount) + " " + record.foreign_currency }}
              <br>
              {{ showMoneyFormat(record.exc_rate) }}
            </p>
          </template>

          <template #customTitlePaymentFee>
            <div class="transaction-fee">
              <p align="center" class="mb-0">{{ $t('Transaction.FeeVND') }}</p>
            </div>
          </template>
          <template slot="payment_fee" slot-scope="text, record">
            <p class="mb-0" align="center">
              {{ showMoneyFormat(handleFeeTrans(record)) + " " + record.currency }}
            </p>
          </template>

          <template #customTitleStatus>
            <p align="center" class="mb-0">{{ $t('Transaction.Status') }}</p>
          </template>
          <template slot="status" slot-scope="text, record">
            <span v-html="showTransactionStatusListHTML(record.status, $t)"></span>
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooter', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
              v-model="currentPage"
              :current="currentPage"
              :page-size="perPage"
              :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-spin>
  </AppMasterVue>
</template>


<script>
import AppMasterVue from "@/layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import DateRangePicker from '@/components/DateRangePicker'
import { mapGetters } from "vuex";
import moment from "moment";
import helper from "../../../until/helper";
import AppConstant from "../../../constants/App";
import { getDatabase, ref, onValue} from "firebase/database";
import TransactionTypeConstant from "../../../constants/TransactionType";
import TransactionColumnConstant from "../../../constants/Transaction";
import UserTypeConstant from "@/constants/UserType";

export default {
  name: "ListTransaction",
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      visible: false,
      loading: false,
      title: 'Sidebar.TransactionList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.TransactionManagement',
          routeName: null,
          active: false, 
        },
        {
          name: 'Sidebar.TransactionList',
          routeName: 'ListTransaction',
          active: true
        }
      ],
      TransactionTypeConstant,
      TransactionColumnConstant,
      UserTypeConstant: UserTypeConstant,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().subtract(29, 'days').format("DD/MM/YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      orderCode: this.isset(this.$route.query.order_code) ? this.$route.query.order_code : "",
      cardNumber: this.isset(this.$route.query.card_number) ? this.$route.query.card_number : "",
      channel: this.isset(this.$route.query.channel) ? this.$route.query.channel : "",
      status: this.isset(this.$route.query.status) && this.$route.query.status != '' ? parseInt(this.$route.query.status) : "",
      method: this.isset(this.$route.query.method) ? this.$route.query.method : "",
      type: this.isset(this.$route.query.type) ? this.$route.query.type : "",
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      profileId: this.isset(this.$route.query.profile_id) ? this.$route.query.profile_id : "",
      updatedBy: this.isset(this.$route.query.updated_by) ? this.$route.query.updated_by : "",
      searchInput: [],
      downloading: false,
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          align: "center",
          display: true,
          class: ''
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "updated_at",
          slots: { title: "customTitleUpdatedAt" },
          scopedSlots: { customRender: "updated_at" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "order_code",
          slots: { title: "customTitleOrderCode" },
          scopedSlots: { customRender: "order_code" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "request_id",
          dataIndex: "request_id",
          slots: { title: "customTitleRequestId" },
          scopedSlots: { customRender: "request_id" },
          display: false,
          align: "center",
          class: 'hide'
        },
        // {
        //   key: "partner_fee",
        //   slots: { title: "customTitleFeePayer" },
        //   scopedSlots: { customRender: "partner_fee" },
        // },
        {
          key: "channel",
          slots: { title: "customTitleChannel" },
          scopedSlots: { customRender: "channel" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "profile_id",
          slots: { title: "customTitleProfile" },
          scopedSlots: { customRender: "profile_id" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "transaction_type",
          slots: { title: "customTitleTransactionType" },
          scopedSlots: { customRender: "transaction_type" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "method",
          slots: { title: "customTitleMethod" },
          scopedSlots: { customRender: "method" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "updated_by",
          slots: { title: "customTitleUpdatedBy" },
          scopedSlots: { customRender: "updated_by" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "card_number",
          slots: { title: "customTitleBankAndCardNumber" },
          scopedSlots: { customRender: "card_number" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "foreign_amount",
          slots: { title: "customTitleReferenceValueAndRateVND" },
          scopedSlots: { customRender: "foreign_amount" },
          display: false,
          align: "center",
          class: ''
        },
        {
          key: "payment_fee",
          slots: { title: "customTitlePaymentFee" },
          scopedSlots: { customRender: "payment_fee" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          display: true,
          align: "center",
          class: 'status'

        },
      ],
      visibleStatus: false,
      columnsStatus: [
        {
          key: 'no',
          dataIndex: 'key',
          slots: { title: "customTitleStatusNo" },
        },
        {
          key: 'status',
          slots: { title: "customTitleStatusStatus" },
          scopedSlots: { customRender: "status" },
        },
        {
          key: 'explain',
          slots: { title: "customTitleStatusExplain" },
          scopedSlots: { customRender: "explain" },
        },
      ],
      dataStatus: [
        {
          key: 1,
          status: 'Constant.TransactionStatus.Completed',
          explain: 'Explain.Completed',
        },
        {
          key: 2,
          status: 'Constant.TransactionStatus.ReceivedMoney',
          explain: 'Explain.ReceivedMoney',
        },
        {
          key: 3,
          status: 'Constant.TransactionStatus.Cancelled',
          explain: 'Explain.Cancelled',
        },
        {
          key: 4,
          status: 'Constant.TransactionStatus.Failed',
          explain: 'Explain.Failed',
        },
        {
          key: 5,
          status: 'Constant.TransactionStatus.Processing',
          explain: 'Explain.Processing',
        },
        {
          key: 6,
          status: 'Constant.TransactionStatus.UnderReview',
          explain: 'Explain.UnderReview',
        },
        {
          key: 7,
          status: 'Constant.TransactionStatus.Linked',
          explain: 'Explain.Linked',
        },
      ],
      options: [],
      selected: ['transaction_type', 'method', 'foreign_amount', 'payment_fee'],
      allSelected: false,
      profileInfo: [],
      profileActive: {},
      profileOptions: [],
      updatedByOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      transactions: "list/getTransactions",
      totalRow: "list/getTotalRow",
      perPage: "list/getPerPage",
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      lang: 'authentications/getLang',
      members: "authentications/getMembers",
      userInfo: "authentications/getUserLogin",
      totalInfo : "transaction/totalInfo",
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    if (this.$route.query.order_code) {
      this.orderCode = this.$route.query.order_code.trim();
    }

    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      order_code: this.orderCode,
      card_number: this.cardNumber,
      channel: this.channel,
      status: this.status,
      method: this.method,
      transaction_type: this.type,
      merchant_id: this.merchantFilter,
      profile_id: this.profileId,
      updated_by: this.updatedBy
    };
    
    this.callApiTransactionList();
    this.callApiTotalInfoTransaction();
    this.callApiProfileInfo();
    this.convertMembersToArray()
  },
  methods: {
    handleLoading: function(){
      this.loading = !this.loading;
    },
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    handleChooseColumn: function(valueCheck) {

      if(this.selected.includes(valueCheck)) {
        let arr = this.selected.filter(function(item) {
            return item !== valueCheck;
        })
        this.selected = arr;
      } else {
        this.selected.push(valueCheck);
      }
      
      this.allSelected = false;
    },
    handleOpenMenu: function() {
      this.visible = true;
    },
    selectAll: function(event) {
      let classes = [];
      if(event.target.checked) {

        Object.values(this.TransactionColumnConstant.TRANSACTION_COLUMN).forEach(vCol => {
          classes.push(vCol.class);
        });
      } 
      this.selected = classes;
    },
    isset: helper.isset,
    getTransactionTypeHtml: helper.getTransactionTypeHtml,
    showChannel: helper.showChannel,
    showAllChannel: helper.showAllChannel,
    showMoneyFormat: helper.showMoneyFormat,
    showTransactionStatusListHTML: helper.showTransactionStatusListHTML,
    showAllTransactionMethod: helper.showAllTransactionMethod,
    getAllTransactionType: helper.getAllTransactionType,
    getAllTransactionStatus: helper.getAllTransactionStatus,
    checkPermission: helper.checkPermission,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    handleFeeTrans: helper.handleFeeTrans,
    checkMerchantActive: helper.checkMerchantActive,
    // showFeePayerTransactions: helper.showFeePayerTransactions,
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
    },
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        order_code: this.orderCode.trim(),
        card_number: this.cardNumber,
        channel: this.channel,
        status: this.status,
        method: this.method,
        transaction_type: this.type,
        merchant_id: this.merchantFilter,
        profile_id: this.profileId,
        updated_by: this.updatedBy,
      };

      this.currentPage = 1;

      this.setRouter();
      this.callApiTransactionList();
      this.callApiTotalInfoTransaction();
      this.callApiProfileInfo();
    },
    clear() {
      this.orderCode = ""
      this.cardNumber = ""
      this.channel = ""
      this.status = ""
      this.method = ""
      this.type = ""
      this.dateFrom = moment().subtract(29, 'days').format("DD/MM/YYYY");
      this.dateTo = moment().format("DD/MM/YYYY");
      this.currentPage = 1;
      this.merchantFilter = [];
      this.profileId = "";
      this.updatedBy = "";

      if (isNaN(this.merchants)) {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchants);
      }

      this.search();
    },
    async callApiTransactionList() {
      this.handleLoading();
      await this.$store.dispatch("list/fetchListTransactions", this.searchInput);
      this.handleLoading();
    },
    callApiTotalInfoTransaction() {
      this.$store.dispatch("transaction/fetchTotalInfoTransaction", this.searchInput);
    },
    setRouter() {
      this.$router.replace({
        name: "ListTransaction",
        query: this.searchInput,
      }).catch(() => {});
    },
    exportData() {
      if (this.downloading) {
        return false;
      }
      this.downloading = true;
      if (this.dateFrom == '' || this.dateTo == '') {
        this.showNotice(
          'error',
          this.$t('Notice.Title.Notice'),
          this.$t('Notice.Message.TimeExportLimit')
        );
        this.downloading = false;
        return false;
      }

      let dayBetween = (Date.parse(moment(this.dateTo, AppConstant.FORMAT_DATE_TIME_2)) - Date.parse(moment(this.dateFrom, AppConstant.FORMAT_DATE_TIME_2)) + 1) / 86400000;
      if (dayBetween > AppConstant.TIME_EXPORT_LIMIT) {
        this.showNotice(
          'error',
          this.$t('Notice.Title.Notice'),
          this.$t('Notice.Message.TimeExportLimit')
        );
        this.downloading = false;
        return false;
      }

      if (this.isset(this.searchInput.lang)) {
        this.searchInput.lang = this.lang;
      } else {
        this.searchInput["lang"] = this.lang;
      }
      
      this.showNotice(
        'warning',
        this.$t('Notice.Title.Notice'),
        this.$t('Notice.Message.MakingARequestToExportData'),
        0
      );

      this.$store.dispatch("transaction/exportTransactions", this.searchInput)
        .then(response => {
          if (response.data) {
            this.listenFileExport(response.data.file_name);
          }
        });
    },
    listenFileExport(fileName) {
      const db = getDatabase();
      const starCountRef = ref(db, process.env.VUE_APP_SERVER + 'v2/export/export_' + fileName);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        this.handleRedirect(data, fileName);
      });
    },
    handleRedirect(data, fileName) {
      if(data && data.path) {
        this.$store.dispatch("transaction/downloadTransactions", {'fileName': fileName})
          .then(response => {
            if (response) {
              var blob = new Blob([ "\ufeff" + response ], { "type" : "text/csv;charset=utf-8" });
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = 'export_' + fileName + '.csv'
              link.click()
              this.downloading = false;
              this.$notification.destroy();
            }
          });
      }
    },
    showNotice(type, title, content, time = 5) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: time,
        style: {
          "margin-top": "50px"
        },
      });
    },
    showModalStatus() {
      this.visibleStatus = true;
    },
    handleOkStatus() {
      this.visibleStatus = false;
    },
    callApiProfileInfo() {
      this.$store.dispatch("invoice/getProfileInfo", { merchant_id: this.merchantFilter }).then(response => {
        if (response.data) {
          let data = response.data;
          let dataProfileInfo = data.profiles[this.merchantFilter];

          dataProfileInfo.forEach((value) => {
            this.profileInfo[value.id] = value.name;

            if(value.status === 1) {
              this.profileActive[value.id] = value.name;
            }
          });

          this.convertProfileActiveToArray();
        }
      });
    },
    getPlaceholderText() {
      return this.$t(TransactionColumnConstant.FILTER_OF_PROFILE_PLACEHOLDER);
    },
    filterOption(input, option) {
      const profile = this.profileOptions.find((item) => item.id === option.key);
      if (profile) {
        return profile.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    convertProfileActiveToArray() {
      this.profileOptions = Object.keys(this.profileActive).map((id) => ({
        id: id,
        label: this.profileActive[id],
      }));
    },
    filterOptionUpdatedBy(input, option){
      const updatedBy = this.updatedByOptions.find((item) => item.id === option.key);
      if (updatedBy) {
        return updatedBy.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    convertMembersToArray() {
      this.updatedByOptions = Object.keys(this.members).map((id) => ({
        id: id,
        label: this.members[id],
      }));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.searchInput = {
          page: value,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          order_code: this.orderCode.trim(),
          card_number: this.cardNumber,
          channel: this.channel,
          status: this.status,
          method: this.method,
          transaction_type: this.type,
          merchant_id: this.merchantFilter,
          profile_id: this.profileId,
        };
        this.setRouter();
        this.callApiTransactionList();
        this.callApiTotalInfoTransaction();
        this.callApiProfileInfo();
      },
    },
    selected: {
      handler: function (value) {
        if(value.length == 8) {
          this.allSelected = true;
        }
        let columnHide = [];
        Object.values(this.columns).forEach(vCol => {

          if(value.includes(vCol.key)) {
            vCol.class = '';
          } else {
            columnHide.push(vCol.key);

            Object.values(this.TransactionColumnConstant.TRANSACTION_COLUMN).forEach(vTrans => {
              if(vCol.key === vTrans.class) {
                vCol.class = 'hide';
              }
            });
          }
        });
      }
    }
  },
};
</script>

<style>
.pr-10 {
  padding-right: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}

.ant-card {
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
}
.mb-30 {
  margin-bottom: 30px !important;
}

.no {
  min-width: 20px;
}
.created-time,
.updated-time {
  min-width: 80px;
}
.order-code {
  min-width: 80px;
}
.transaction-type {
  min-width: 80px;
}
.payment-method {
  min-width: 90px;
}
.bank {
  min-width: 90px;
}
.transaction-amount {
  min-width: 90px;
}
.transaction-reference-value {
  min-width: 90px;
}
.transaction-fee {
  min-width: 100px;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 10px !important;
}
.btn-export svg {
  vertical-align: baseline;
}
.modal-status .ant-modal-footer > div > button:first-child {
  display: none;
}
.hide{
  display: none;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove{
  line-height: 18px;
}
.ant-dropdown-content .all{
  border-bottom: 1px solid #E5E5E5;
}
.ant-checkbox-wrapper{
  width: 100%;
}
th.status,
td.status{
  width: 150px;
}
</style>
