<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>

    
    <div class="filter-merchant" v-if="isNaN(merchants)">
      <h5>{{ $t('Label.FilterByMerchant') }}</h5>
      <a-select style="width: 250px" @change="selectMerchant" default-value="">
        <a-select-option value="">
          {{ $t('Dashboard.AllMerchant') }}
        </a-select-option>
        <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
          {{ merchant.name }}
        </a-select-option>
      </a-select>
    </div>
    
    <div class="row pd-10">
      <div class="col-12 col-md-4 mt-2">
        <a-card :title="$t('Label.MerchantBalance')" class="balance-card">
            <a-row class="pb-0">
              <a-col :md="12" :sm="24">
                <a-badge status="processing" />
                <span class="balance-txt">{{ $t('Label.AvailableBalances') + ':' }} </span>
              </a-col>
              <a-col :md="12" :sm="24">
                <span class="balance-txt">{{ showMoneyFormat(balance) }} </span>
              </a-col>
            </a-row>

            <a-row class="pb-0" v-if="checkMerchantActiveDisbursement(user)">
              <a-col :md="12" :sm="24">
                <a-badge status="processing" />
                <span class="balance-txt">{{ $t('Label.DisbursementBalance') + ':' }} </span>
              </a-col>
              <a-col :md="12" :sm="24">
                <span class="balance-txt">{{ showMoneyFormat(disbersementBalance) }} </span>
              </a-col>
            </a-row>

            <!--          <a-row class="pb-0" v-if="checkMerchantActiveDisbursement(user)">-->
            <!--            <a-col :md="12" :sm="24">-->
            <!--              <a-badge status="processing" />-->
            <!--              <span class="balance-txt">{{ $t('Label.DisbursementBalanceMax') + ':' }} </span>-->
            <!--            </a-col>-->
            <!--            <a-col :md="12" :sm="24">-->
            <!--              <span class="balance-txt">{{ showMoneyFormat(disbersementBalance + collectionBalance) }} </span>-->
            <!--            </a-col>-->
            <!--          </a-row>-->
          <a-row class="pb-0" v-if="this.checkMenuOpened('StatisticsOfAvailableBalanceUsd') && checkPermission(user, 'canViewMerchantUsdLog')">
            <a-col :md="12" :sm="24">
              <a-badge status="processing" />
              <span class="balance-txt">{{ $t('Label.BalanceUsd') + ':' }} </span>
            </a-col>
            <a-col :md="12" :sm="24">
              <span class="balance-txt">{{ showMoneyFormat(balanceUsd) }} </span>
            </a-col>
          </a-row>
            <a-row class="pb-0">
              <a-col :md="12" :sm="24">
                <a-badge status="processing" />
                <span class="balance-txt">{{ $t('Label.FreezeBalance') + ':' }} </span>
              </a-col>
              <a-col :md="12" :sm="24">
                <span class="balance-txt">{{ showMoneyFormat(freezeBalance) }} </span>
              </a-col>
            </a-row>

<!--            <a-row class="pb-0" v-if="checkMerchantActiveSDKWallet(user)">-->
<!--              <a-col :md="12" :sm="24">-->
<!--                <a-badge status="processing" />-->
<!--                <span class="balance-txt">{{ $t('Label.WalletBalance') + ':' }} </span>-->
<!--              </a-col>-->
<!--              <a-col :md="12" :sm="24">-->
<!--                <span class="balance-txt">{{ showMoneyFormat(Math.round(walletBalance)) }} </span>-->
<!--              </a-col>-->
<!--            </a-row>-->
        </a-card>
      </div>

      <div class="col-12 col-md-4 mt-2">
        <a-card class="revenue-card space-align-container" :title="$t('Dashboard.Revenue') + ' | ' + $t(revenueTitle)">
          <a-dropdown :trigger="['click']"  slot="extra">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="ellipsis" :style="{ fontSize:'20px', color:'black' }" />
            </a>
            <a-menu slot="overlay" @click="filterRevenue">
              <a-menu-item :key="DashBoardConstant.TODAY">
                {{ $t('Dashboard.Today') }}
              </a-menu-item>
              <a-menu-item :key="DashBoardConstant.THIS_WEEK">
                {{ $t('Dashboard.ThisWeek') }}
              </a-menu-item>
              <a-menu-item :key="DashBoardConstant.THIS_MONTH">
                {{ $t('Dashboard.ThisMonth') }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-row>
            <a-col :span="6" class="pr-24" align="center">
              <a-icon type="dollar" :style="{ fontSize:'50px', color:'#07EE00' }" />
            </a-col>
            <a-col :span="18">
              <span class="amount">{{ showMoneyFormat(revenue) }} VND</span>
              <br>
              <span class="count-revenue">{{ showMoneyFormat(successTrans) }}</span>
              <span class="transactions"> {{ $t('Dashboard.Transactions') }}</span>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <div class="col-12 col-md-4 mt-2">
        <a-card class="refund-card" :title="$t('Dashboard.Refund') + ' | ' + $t(refundTitle)">
          <a-dropdown :trigger="['click']"  slot="extra">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="ellipsis" :style="{ fontSize:'20px', color:'black' }" />
            </a>
            <a-menu slot="overlay" @click="filterRefund">
              <a-menu-item :key="DashBoardConstant.TODAY">
                {{ $t('Dashboard.Today') }}
              </a-menu-item>
              <a-menu-item :key="DashBoardConstant.THIS_WEEK">
                {{ $t('Dashboard.ThisWeek') }}
              </a-menu-item>
              <a-menu-item :key="DashBoardConstant.THIS_MONTH">
                {{ $t('Dashboard.ThisMonth') }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-row>
            <a-col :span="6" class="pr-24" align="center">
              <a-icon type="dollar" :style="{ fontSize:'50px', color:'#F82C04' }" />
            </a-col>
            <a-col :span="18">
              <span class="amount">{{ showMoneyFormat(refund) }} VND</span>
              <br>
              <span class="count-refund">{{ showMoneyFormat(refundTrans) }}</span>
              <span class="transactions"> {{ $t('Dashboard.Transactions') }}</span>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </div>

    <div class="pd-10">
      <a-card :title="$t('Dashboard.TransactionStatistics')">
        <div class="filter">
          <a-select v-model="chartFilter" style="width: 150px" @change="filterChart">
            <a-select-option :value="DashBoardConstant.DAYS">
              {{ $t('Dashboard.ByDay') }}
            </a-select-option>
            <a-select-option :value="DashBoardConstant.WEEK">
              {{ $t('Dashboard.ByWeek') }}
            </a-select-option>
            <a-select-option :value="DashBoardConstant.MONTH">
              {{ $t('Dashboard.ByMonth') }}
            </a-select-option>
          </a-select>
        </div>
        <Highcharts :options="chartOptions"></Highcharts>
      </a-card>
    </div>

    <div class="pd-10">
      <a-card :title="$t('Dashboard.ExchangeRate')" class="scroll-x">
        <table class="table table-borderless nowrap">
          <thead>
            <tr>
              <th scope="col" class="text-center col-md-3">{{ $t('Dashboard.Currency') }}</th>
              <th scope="col" class="text-center col-md-4">{{ $t('Dashboard.CurrencyCode') }}</th>
              <th scope="col">{{ $t('Dashboard.VND') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rate, id) in exchangeRate" :key="id" class="border-top-rate">
              <td class="text-center">{{ CurrencyNameConstant.CURRENCY_NAME[rate.currency] !== undefined ? CurrencyNameConstant.CURRENCY_NAME[rate.currency] : '' }}</td>
              <td class="text-center">{{ rate.currency }}</td>
              <td>{{ showMoneyFormat(rate.rate) }}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
    </div>

    <div class="pd-10">
      <a-card :title="$t('Dashboard.DepositAndWithdrawalStatistics')">
        <div class="filter">
          <a-select v-model="balanceFilter" style="width: 150px" @change="filterBalance">
            <a-select-option :value="DashBoardConstant.THIS_WEEK">
              {{ $t('Dashboard.ThisWeek') }}
            </a-select-option>
            <a-select-option :value="DashBoardConstant.THIS_MONTH">
              {{ $t('Dashboard.ThisMonth') }}
            </a-select-option>
            <a-select-option :value="DashBoardConstant.THIS_QUARTER">
              {{ $t('Dashboard.ThisQuarter') }}
            </a-select-option>
          </a-select>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 mt-2">
            <a-card :title="$t('Dashboard.TotalDeposit')">
              <a-row>
                <a-col :span="6" class="pr-24" align="center">
                  <a-icon type="import" :style="{ fontSize:'50px', color:'#07EE00' }" />
                </a-col>
                <a-col :span="18">
                  <span class="amount">{{ showMoneyFormat(balanceIncrease) }}</span>
                  <span class="transactions"> VND</span>
                </a-col>
              </a-row>
            </a-card>
          </div>

          <div class="col-12 col-md-6 mt-2">
            <a-card :title="$t('Dashboard.TotalWithdrawals')">
              <a-row>
                <a-col :span="6" class="pr-24" align="center">
                  <a-icon type="export" :style="{ fontSize:'50px', color:'#F82C04' }" />
                </a-col>
                <a-col :span="18">
                  <span class="amount">{{ showMoneyFormat(balanceReduction) }}</span>
                  <span class="transactions"> VND</span>
                </a-col>
              </a-row>
            </a-card>
          </div>
        </div>
      </a-card>
    </div>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue'
import moment from "moment";
import helper from '../../until/helper';
import AppConstant from "../../constants/App";
import DashBoardConstant from "../../constants/DashBoard";
import CurrencyNameConstant from "../../constants/CurrencyName";
import UserTypeConstant from "@/constants/UserType";

export default {
  name: 'Home',
  components: {
    AppMasterVue,
    PageTitle,
    Highcharts: Chart,
  },
  data() {
    return {
      title: 'Dashboard',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Title.Dashboard',
          routeName: 'Home',
          active: true, 
        },
      ],
      chartOptions: {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: ''
        },
        // subtitle: {
        //     text: 'Source: WorldClimate.com'
        // },
        xAxis: [
          {
            categories: [],
            crosshair: true
          }
        ],
        yAxis: [
          { // Primary yAxis
            labels: {
              formatter: function() {
                return helper.showMoneyFormat(this.value) + 'M';
              },
            },
            title: {
              text: this.$t('Dashboard.GMV'),
              style: {
                fontWeight: 'bold',
              },
            },
          },
          { // Secondary yAxis
            title: {
              text: this.$t('Dashboard.NumberOfTransactions'),
              style: {
                fontWeight: 'bold',
              },
            },
            labels: {
              formatter: function() {
                return helper.showMoneyFormat(this.value);
              },
            },
            opposite: true
          }
        ],
        tooltip: {
          formatter: function() {
            return this.points.reduce(function (s, point) {
              return s + '<br/>' + point.series.name + ': ' +
                helper.showMoneyFormat(point.y) + (point.series.name.includes("GMV") ? 'M' : '');
            }, '<b>' + this.x + '</b>');
          },
          shared: true
        },
        series: [
          {
            name: this.$t('Dashboard.GMV'),
            type: 'column',
            data: [],
            tooltip: {
              valueSuffix: 'M'
            }
          },
          {
            name: this.$t('Dashboard.NumberOfTransactions'),
            type: 'spline',
            yAxis: 1,
            data: [],
          }
        ]
      },
      DashBoardConstant: DashBoardConstant,
      CurrencyNameConstant,
      balance: 0,
      freezeBalance: 0,
      balanceUsd: 0,
      disbersementBalance: 0,
      collectionBalance: 0,
      walletBalance: 0,
      chartFilter: DashBoardConstant.DAYS,
      dates: {},
      categories: [],
      dataGMV: [],
      dataTransaction: [],
      revenue: 0,
      successTrans: 0,
      revenueTitle: 'Dashboard.Today',
      refund: 0,
      refundTrans: 0,
      refundTitle: 'Dashboard.Today',
      balanceFilter: DashBoardConstant.THIS_WEEK,
      balanceIncrease: 0,
      balanceReduction: 0,
      merchantFilter: [],
      exchangeRate: []
    }
  },
  computed: {
    ...mapGetters({
      merchants: 'authentications/getMerchants',
      lang: 'authentications/getLang',
      user: 'authentications/getUserLogin',
      idSDKMerchant: 'authentications/getIdSDKMerchant',
      menu: "authentications/getMenu",
    }),
  },
  mounted() {

  },
  methods: {
    isset: helper.isset,
    checkMenuOpened(tabCode){
      if (parseInt(this.user.type) === UserTypeConstant.MASTER_MERCHANT || parseInt(this.user.type) === UserTypeConstant.SUB_MASTER_MERCHANT){
        return true;
      }
      if (this.menu.includes(tabCode)){
        return true;
      }else{
        return false;
      }
    },
    checkPermission: helper.checkPermission,
    convertDayToDateTime: helper.convertDayToDateTime,
    getStartDateForFilter: helper.getStartDateForFilter,
    showMoneyFormat: helper.showMoneyFormat,
    checkMerchantActiveSDKWallet: helper.checkMerchantActiveSDKWallet,
    checkMerchantActiveDisbursement: helper.checkMerchantActiveDisbursement,
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
      this.chartFilter = DashBoardConstant.DAYS;
      this.balanceFilter = DashBoardConstant.THIS_WEEK;
      this.loadDataFisrt();
      this.filterRevenue({"key": DashBoardConstant.TODAY});
      this.filterRefund({"key": DashBoardConstant.TODAY});
    },
    filterRevenue(e) {
      this.revenueTitle = this.showTitleFilter(e.key);
      var startDate = this.getStartDateForFilter(e.key);
      var endDate = moment().format(AppConstant.FORMAT_DATE_TIME);
      this.$store.dispatch("dashboard/getDataChart", {
        "from": startDate,
        "to": endDate,
        "type": e.key,
        "merchant_id": this.merchantFilter
      })
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.revenue = response.data[0].total_amount;
          this.successTrans = response.data[0].total_trans;
        } else {
          this.revenue = 0;
          this.successTrans = 0;
        }
      });
    },
    filterRefund(e) {
      this.refundTitle = this.showTitleFilter(e.key);
      var startDate = this.getStartDateForFilter(e.key);
      var endDate = moment().format(AppConstant.FORMAT_DATE_TIME);
      this.$store.dispatch("dashboard/getDataChart", {
        "from": startDate,
        "to": endDate,
        "type": e.key,
        "refund": 1,
        "merchant_id": this.merchantFilter
      })
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.refund = response.data[0].total_amount;
          this.refundTrans = response.data[0].total_trans;
        } else {
          this.refund = 0;
          this.refundTrans = 0;
        }
      });
    },
    filterChart(value) {
      switch (value) {
        case DashBoardConstant.DAYS:
          this.dates = this.convertDayToDateTime(31, value);
          this.$store.dispatch("dashboard/getDataChart", {
            "from": this.dates.from,
            "to": this.dates.to,
            "type": value,
            "merchant_id": this.merchantFilter
          })
          .then(response => {
            if (response.data) {
              this.formatDataChart(value, response.data)
            }
          });
          break;
        case DashBoardConstant.WEEK:
          this.dates = this.convertDayToDateTime(10, value);
          this.$store.dispatch("dashboard/getDataChart", {
            "from": this.dates.from,
            "to": this.dates.to,
            "type": value,
            "merchant_id": this.merchantFilter
          })
          .then(response => {
            if (response.data) {
              this.formatDataChart(value, response.data)
            }
          });
          break;
        case DashBoardConstant.MONTH:
          this.dates = this.convertDayToDateTime(12, value);
          this.$store.dispatch("dashboard/getDataChart", {
            "from": this.dates.from,
            "to": this.dates.to,
            "type": value,
            "merchant_id": this.merchantFilter
          })
          .then(response => {
            if (response.data) {
              this.formatDataChart(value, response.data)
            }
          });
          break;
      }
    },
    filterBalance(value) {
      var startDate = this.getStartDateForFilter(value);
      this.$store.dispatch("dashboard/getReportBalanceMerchant", { "from": startDate, "merchant_id": this.merchantFilter })
      .then(response => {
        if (response.data) {
          this.balanceIncrease = response.data.total_cash_out;
          this.balanceReduction = response.data.total_cash_in;
        } else {
          this.balanceIncrease = 0;
          this.balanceReduction = 0;
        }
      });
    },
    formatDataChart(type, data) {
      var thisDay = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME);
      var dateTo = moment(this.dates.to, AppConstant.FORMAT_DATE_TIME);
      var thisWeek = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).isoWeek();
      var weekTo = moment(this.dates.to, AppConstant.FORMAT_DATE_TIME).isoWeek();
      var monthTo = moment(this.dates.to, AppConstant.FORMAT_DATE_TIME).month() + 1;
      var thisYear = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).year();
      var yearTo = moment(this.dates.to, AppConstant.FORMAT_DATE_TIME).year();

      switch (type) {
        case DashBoardConstant.DAYS:
          this.resetDataChart();

          thisDay = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME);

          while (thisDay <= dateTo) {
            var dataInDay = data.find(element => element.dates == thisDay.format(AppConstant.FORMAT_DATE_TIME));

            this.categories.push(thisDay.format(AppConstant.FORMAT_DATE_TIME_2));

            if (dataInDay) {
              this.dataGMV.push(parseFloat((parseFloat(dataInDay.total_amount) / 1000000).toFixed(2)));
              this.dataTransaction.push(parseFloat(dataInDay.total_trans));
            } else {
              this.dataGMV.push(0);
              this.dataTransaction.push(0);
            }

            thisDay = moment(thisDay.add(1, 'days').format(AppConstant.FORMAT_DATE_TIME), AppConstant.FORMAT_DATE_TIME);
          }

          // lay doanh thu hom nay trong lan load trang dau tien
          if (this.revenue == 0 && this.successTrans == 0) {
            var dataThisDay = data.find(element => element.dates == moment().format(AppConstant.FORMAT_DATE_TIME));
            if (dataThisDay) {
              this.revenue = dataThisDay.total_amount;
              this.successTrans = dataThisDay.total_trans;
            }
          }

          this.setDataChart();
          break;
        case DashBoardConstant.WEEK:
          this.resetDataChart();
          var max_week = Math.max(
                moment(new Date(thisYear, 11, 31)).isoWeek()
              , moment(new Date(thisYear, 11, 31-7)).isoWeek()
          )
          thisWeek = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).isoWeek();
          thisYear = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).year();

          while (
            thisYear < yearTo
            || (thisWeek <= weekTo && thisYear == yearTo)
          ) {
            var thisWeekDisplay = this.$t('Dashboard.Week') + ' ' + (thisWeek) + '/' + thisYear;
            var dataInWeek = data.find(element => (element.weeks == thisWeek && element.years == thisYear));

            this.categories.push(thisWeekDisplay);
            if (dataInWeek) {
              this.dataGMV.push(parseFloat((parseFloat(dataInWeek.total_amount) / 1000000).toFixed(2)));
              this.dataTransaction.push(parseInt(dataInWeek.total_trans));
            } else {
              this.dataGMV.push(0);
              this.dataTransaction.push(0);
            }

            thisWeek++;
            if (thisWeek > max_week) {
              thisWeek = 1;
              thisYear++;
            }
          }
          this.setDataChart();
          break;
        case DashBoardConstant.MONTH:
          this.resetDataChart();

          var thisMonth = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).month() + 1;
          thisYear = moment(this.dates.from, AppConstant.FORMAT_DATE_TIME).year();

          while (
            (thisMonth <= monthTo && thisYear == yearTo)
            || thisYear < yearTo
          ) {
            var thisMonthDisplay = thisMonth + '/' + thisYear;
            var dataInMonth = data.find(element => (element.months == thisMonth && element.years == thisYear));

            this.categories.push(thisMonthDisplay);
            if (dataInMonth) {
              this.dataGMV.push(parseFloat((parseFloat(dataInMonth.total_amount) / 1000000).toFixed(2)));
              this.dataTransaction.push(parseInt(dataInMonth.total_trans));
            } else {
              this.dataGMV.push(0);
              this.dataTransaction.push(0);
            }

            thisMonth++;
            if (thisMonth == 13) {
              thisMonth = 1;
              thisYear++;
            }
          }
          this.setDataChart();
          break;
      }
    },
    setDataChart() {
      this.chartOptions.xAxis = [
        {
          categories: this.categories,
          crosshair: true
        }
      ];
      this.chartOptions.series = [
        {
          name: this.$t('Dashboard.GMV'),
          type: 'column',
          data: this.dataGMV,
          tooltip: {
            valueSuffix: 'M'
          }
        },
        {
          name: this.$t('Dashboard.NumberOfTransactions'),
          type: 'spline',
          yAxis: 1,
          data: this.dataTransaction,
        }
      ];

      this.chartOptions.yAxis = [
        { // Primary yAxis
          labels: {
            format: '{value}M',
          },
          title: {
            text: this.$t('Dashboard.GMV'),
            style: {
              fontWeight: 'bold',
            },
          },
        },
        { // Secondary yAxis
          title: {
            text: this.$t('Dashboard.NumberOfTransactions'),
            style: {
              fontWeight: 'bold',
            },
          },
          labels: {
            format: '{value}',
          },
          opposite: true
        }
      ];
    },
    resetDataChart() {
      this.categories = [];
      this.dataGMV = [];
      this.dataTransaction = [];
    },
    showTitleFilter(type) {
      switch (type) {
        case DashBoardConstant.TODAY:
          return 'Dashboard.Today';
        case DashBoardConstant.THIS_WEEK:
          return 'Dashboard.ThisWeek';
        case DashBoardConstant.THIS_MONTH:
          return 'Dashboard.ThisMonth';
      }
    },
    loadDataFisrt() {
      // set balance
      this.$store.dispatch("dashboard/getBalanceMerchant", {"merchant_id": this.merchantFilter})
      .then(response => {
        if (response.data) {
          this.balance = response.data.balance;
          this.freezeBalance = response.data.freeze_balance;
          this.disbersementBalance = response.data.disbursement_balance;
          this.collectionBalance = response.data.collection_balance;
          this.balanceUsd = response.data.usd_balance;
        } else {
          this.balance = 'NaN';
          this.freezeBalance = 'NaN';
          this.disbersementBalance = 'NaN';
          this.collectionBalance = 'NaN';
          this.balanceUsd = 'NaN';
        }
      });

      // set chart value
      this.dates = this.convertDayToDateTime(31, DashBoardConstant.DAYS);
      this.$store.dispatch("dashboard/getDataChart", {
        "from": this.dates.from,
        "to": this.dates.to,
        "type": DashBoardConstant.DAYS,
        "merchant_id": this.merchantFilter
      })
      .then(response => {
        if (response.data) {
          this.formatDataChart(DashBoardConstant.DAYS, response.data)
        }
      });

      // set refund value
      let startDate = this.getStartDateForFilter(DashBoardConstant.TODAY);
      let endDate = moment().format(AppConstant.FORMAT_DATE_TIME);
      this.$store.dispatch("dashboard/getDataChart", {
        "from": startDate,
        "to": endDate,
        "type": DashBoardConstant.TODAY,
        "merchant_id": this.merchantFilter,
        "refund": 1
      })
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.refund = response.data[0].total_amount;
          this.refundTrans = response.data[0].total_trans;
        } else {
          this.refund = 0;
          this.refundTrans = 0;
        }
      });

      // set request balance value
      startDate = this.getStartDateForFilter(DashBoardConstant.THIS_WEEK);
      this.$store.dispatch("dashboard/getReportBalanceMerchant", { "from": startDate, "to": endDate, "merchant_id": this.merchantFilter })
      .then(response => {
        if (response.data) {
          this.balanceIncrease = response.data.total_cash_out;
          this.balanceReduction = response.data.total_cash_in;
        } else {
          this.balanceIncrease = 0;
          this.balanceReduction = 0;
        }
      });

      // set exchange rate value
      this.$store.dispatch("dashboard/getExchangeRate")
      .then(response => {
        if (response.data) {
          this.exchangeRate = response.data;
        }
      });

      // set wallet balance value
      // if (this.checkMerchantActiveSDKWallet) { // TODO Tạm ẩn màn Thống kế biến động số dư ví - task 3428
      //   this.$store.dispatch("dashboard/getWalletBalance", { "merchant_id": this.merchantFilter, "id_sdk_whitelabel": this.idSDKMerchant })
      //   .then(response => {
      //     if (response.data) {
      //       this.walletBalance = response.data;
      //     } else {
      //       this.walletBalance = 0;
      //     }
      //   });
      // }
    },
    redrawChart() {
      this.setDataChart();
    },
  },
  beforeMount() {
    // set merchant filter
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = this.merchants;
    }

    this.loadDataFisrt();
  },
  watch: {
    lang: function () {
      this.redrawChart();
    },
  },
}
</script>

<style>
.pd-10 {
  padding: 10px !important;
}
.pr-24 {
  padding-right: 24px !important;
}
.amount {
  font-weight: 400;
  font-size: 24px;
  line-height: 33.6px;
}
.count-revenue {
  font-weight: 400;
  font-size: 14px;
  color: #198754;
}
.count-refund {
  font-weight: 400;
  font-size: 14px;
  color: #e42e2e;
}
.transactions {
  font-weight: 400;
  font-size: 14px;
}
.highcharts-credits {
  display: none;
}
.nowrap {
  white-space: nowrap;
}
.border-top-rate {
  border-top: 1px solid #8080801f;
}
.scroll-x {
  overflow-x: scroll;
}
.balance-txt {
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
}
.balance-card, .revenue-card, .refund-card {
  height: 100%;
}
.revenue-card .ant-row, .refund-card .ant-row {
  padding-bottom: 0;
}
</style>
