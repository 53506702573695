<template>
    <AppMasterVue >
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
        <section class="section disbursement-body custom-section" style="min-height: 75vh;">
            <div class="card">
            <div class="p-3">
              <div class="disbursement-money"><div>{{ $t('Disbursement.Balance') }}:</div> <span class="disbursement-balance">{{ showMoneyFormat(disbursementBalance) }} VND</span></div>
            </div>
          </div>
            <div class="card box-request__disbursement">
                <div class="p-3">
                    <a-tabs type="card" :active-key="activeKey" @change="handleChangeTabs">
                        <a-tab-pane key="0" :tab="$t('Disbursement.Title')">
                            <a-form-model
                                  layout="vertical"
                                  ref="ruleForm"
                                  :model="formData"
                                  :rules="rules"
                              >
                              <a-row :gutter="[16,16]">
                                  <a-col :lg="12" :xs="24">
                                    <a-row class="first-wf">
                                        <a-col>
                                            <a-form-model-item
                                                ref="bankNo"
                                                prop="bankNo"
                                            >
                                                <span slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.ReceivingBank') }} (<span class="text-danger">*</span>)</span>
                                                <a-select
                                                    show-search
                                                    :filter-option="filterOption"
                                                    :placeholder="$t('Disbursement.Request.ReceivingBank')"
                                                    v-model="formData.bankNo"
                                                    @change="resetCheckAccount"
                                                >
                                                    <a-select-option v-for="(item, key) in listBank" :value="key" :key="key" :disabled="!item.status">
                                                        {{ item.name }}
                                                    </a-select-option>
                                                </a-select>
                                                </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-model-item
                                                ref="type"
                                                prop="type"
                                                class="form-data-type"
                                                style="margin-top: 8px;"
                                            >
                                              <a-radio-group v-model="formData.type">
                                                <a-row>
                                                  <a-col :lg="7" :md="24" :xs="24" style="font-weight: 600; color: rgba(0, 0, 0, 0.85);"><span class="font-svn__poppins__medium">{{ $t('Disbursement.Request.TransferTo') }}</span> (<span class="text-danger">*</span>)</a-col>
                                                  <a-col :lg="7" :md="12" :xs="12">
                                                    <a-radio value="0">
                                                      {{ $t('Disbursement.Request.AccountNumber') }}
                                                    </a-radio>
                                                  </a-col>
                                                  <a-col :xl="6" :lg="8" :md="12" :xs="12">
                                                    <a-radio value="1">
                                                      {{ $t('Disbursement.Request.CardNumber') }}
                                                    </a-radio>
                                                  </a-col>
                                                </a-row>
                                              </a-radio-group>
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                            <a-row class="p-0">
                                                <a-col :lg="24" class="custom-label">
                                                  <span class="font-svn__poppins__medium">{{ $t('Disbursement.Request.AccountNumberCardNumber') }} (<span class="text-danger">*</span>)</span>
                                                </a-col>
                                                <a-col :lg="18" :xs="14">
                                                    <a-form-model-item
                                                        ref="accountNumberCardNumber"
                                                        prop="accountNumberCardNumber"
                                                        style="margin-right: 5px"
                                                        class="account-number"
                                                    >
                                                        <a-input type="text" v-model.trim="formData.accountNumberCardNumber" :placeholder="$t('Disbursement.Request.AccountNumberCardNumber')" @change="resetCheckAccount" @keypress="onlyAlphabetNumber" :maxLength="19"/>
                                                    </a-form-model-item>
                                                </a-col>
                                                <a-col :lg="4" :offset="2" :xs="8">
                                                    <a-form-model-item style="float:right">
                                                        <a-button @click="checkAccount" type="primary">
                                                          <div class="check-account-btn"><span v-if="loadingCheckAccount" class="spinner-border"></span>{{ $t('Disbursement.Request.Check') }}</div>
                                                        </a-button>
                                                    </a-form-model-item>
                                                </a-col>
                                            </a-row>
                                        </a-col>
                                    </a-row>
                                    <div v-if="isCheckAccount" class="extra-form-data">
                                      <a-row style="padding-bottom: 15px">
                                        <a-col>
                                          <a-form-model-item
                                              ref="accountName"
                                              prop="accountName"
                                              class="text-only-block"
                                          >
                                            <span style="margin-right: 15px" slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.AccountName') }} (<span class="text-danger">*</span>)</span>
                                            <span style="font-weight: 700">{{ formData.accountName }}</span>
                                          </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                          <a-form-model-item ref="amount" prop="amount">
                                            <span slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.TransferAmount') }} (<span class="text-danger">*</span>)</span>
                                            <a-input id="input-amount"
                                                    class="transfer-amount-input"
                                                    v-model="formData.amount"
                                                    :max-length=20
                                                    suffix="VND"
                                                    v-mask="currencyMaskVND"
                                                    :placeholder="$t('Disbursement.Request.Amount')"
                                                    @change="handleAmount($event.target.value)"
                                                    :disabled="isDisabledForm"
                                            />
                                            <span id="btn-all-amount" :class="isDisabledForm ? 'disabled-span-btn' : ''" @click="handleTotalAmount()" style="top: -2px;">{{ $t('Disbursement.Request.All') }}</span>
                                          </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                          <a-form-model-item class="text-only-block">
                                            <span style="margin-right: 8px" slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.Fee') }}:</span>
                                            <span style="font-weight: 700">{{ showMoneyFormat(Math.round(fee)) }}</span>
                                          </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                          <a-form-model-item class="text-only-block">
                                            <span style="margin-right: 8px" slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.TotalAmount') }}:</span>
                                            <span style="font-weight: 700">{{ showMoneyFormat(totalAmount) }}</span>
                                          </a-form-model-item>
                                        </a-col>
                                        <a-col>
                                          <a-form-model-item
                                              ref="content"
                                              prop="content"
                                              class="transfer-description"
                                          >
                                            <span slot="label" class="font-svn__poppins__medium">{{ $t('Disbursement.Request.Content') }}:</span>
                                            <a-input type="text" v-model="formData.content" :max-length="100" :placeholder="user.user.username + ' chuyen tien'" :disabled="isDisabledForm"/>
                                          </a-form-model-item>
                                        </a-col>
                                      </a-row>
                                      <p v-if="!isEnableAuth">{{ $t('Balance.Title.descriptionOtp')}} <a :href="'/account/security'" class="fw-bold">{{ $t('Security.TextRegisterNow') }}</a></p>
                                      <a-button v-if="!checkIsMasterMerchant(user)" type="primary w-100" :disabled="checkSubmit" @click="onSubmit" >
                                        <div class="check-account-btn justify-content-center"><span v-if="loading" class="spinner-border"></span>{{ $t('Disbursement.Request.Transfer') }}</div>
                                      </a-button>
                                    </div>
                                  </a-col>
                              </a-row>
                          </a-form-model>
                          <OtpForm
                            :accounts="user.accounts"
                            :visibleModal="visibleModalRegister"
                            @close-modal="handleModalRegisterClose"
                            :typeAuth="OtpConstant.OTP_TYPE_VERIFY_TRANSACTION"
                            @data-loaded="handleDataLoaded"
                            @submit="handleSubmitOtp">
                          </OtpForm>
                        </a-tab-pane>
                        <a-tab-pane v-if="checkActiveModuleByBatch()" key="1" :tab="$t('Disbursement.DisbursementByBatch')">
                          <DisbursementByBatch />
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </section>
      <a-modal
          :maskClosable="false"
          :closable="false"
          v-model="isVisibleModalSuccess"
          wrapClassName="modal-success"
          footer
      >
        <div class="alert-content mb-2">
          <div class="alert-icon">
            <i class="bi bi-check-circle"></i>
            <p class="alert-status">{{ $t('Notice.Message.TransactionSuccessful') }}</p>
          </div>
        </div>
        <div class="alert-footer text-center">
          <a-button id="btn-close-alert" class="me-5" @click="reloadPage">{{ $t('Balance.ButtonTitle.Forward') }}</a-button>
          <a-button id="btn-show-transaction" @click="handleClickViewTransaction">
            {{ $t('Balance.ButtonTitle.viewTransaction') }}
          </a-button>
        </div>
      </a-modal>

      <a-modal v-model="visibleModal" footer :closable="false"  :width="435" id="modal-alert" :maskClosable="false">
          <div class="alert-content mb-2">
              <div class="alert-icon alert-icon__warning mb-3">
                  <i class="bi bi-exclamation-triangle-fill"></i>
              </div>
              <div class="alert-message text-center">
                  <p class="mb-3 font-svn__poppins__medium">{{ txtMessage }}</p>
              </div>
          </div>
          <div class="alert-footer text-center">
              <a-button id="btn-close-alert" @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
          </div>
      </a-modal>

      <a-modal
        id="fee-modal"
        :title="$t('Disbursement.IsOffFeeByBatchTitle')"
        :visible="popupFeeVisible"
        :cancel-text="$t('Balance.ButtonTitle.close')"
        :ok-text="null"
        @cancel="handleRedirectCancel">
        <p>{{ $t('Disbursement.IsOffFeeByBatch') }}</p>
        <p>{{ $t('Disbursement.IsOffFeeByBatchSub') }}</p>
      </a-modal>

      <PopupRegisterAuth :popupVisible="popupRequestVisible"></PopupRegisterAuth>
    </AppMasterVue>
</template>
  
<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import CallApi from '../../../services/api.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import helper from '../../../until/helper';
import DisbursementByBatch from './DisbursementByBatch.vue';
import OtpForm from "@/components/OtpTransaction/OtpForm";
import OtpConstant from "@/constants/OtpConstant";
import PopupRegisterAuth from "@/components/Popups/PopupRegisterAuth";

const currencyMaskVND = createNumberMask({
  prefix: '',
  allowDecimal: false
});

const codeMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true
});

export default {
    name: 'MerchantRequestDisbursement',
    components: {
        AppMasterVue,
        PageTitle,
        DisbursementByBatch,
        OtpForm,
        PopupRegisterAuth
    },
    data() {
        let checkCardNumber = (rule, value, callback) => {
            if (value.trim() === "") {
                callback(new Error(this.$t('Message.Required', {'name': this.$t('Disbursement.Request.AccountNumberCardNumber')})));
            } else {
                if (value) {
                    let regexCardNumber = /^[a-zA-Z0-9]*$/;
                    if (regexCardNumber.test(value)) {
                        callback();
                    } else {
                        callback(new Error(this.$t('Message.AlphaNumeric', {'name': this.$t('Disbursement.Request.AccountNumberCardNumber')})));
                    }
                } else {
                    callback();
                }
            }
        };

        let checkContent = (rule, value, callback) => {
            if (value.trim() === "") {
                // callback(new Error(this.$t('Message.Required', {'name': this.$t('Disbursement.Request.Content')})));
                callback();
            } else {
                if (value) {
                    let regexCardNumber = /^[a-zA-Z0-9\s]*$/;
                    if (regexCardNumber.test(value)) {
                        callback();
                    } else {
                        callback(new Error(this.$t('Message.AlphaNumeric', {'name': this.$t('Disbursement.Request.Content')})));
                    }
                } else {
                    callback();
                }
            }
        };

        let checkAmount = (rule, value, callback) => {
            // let disbursementBalanceMax = this.disbursementBalance > 2000000000 ? 2000000000 : this.disbursementBalance;
            if (value.trim() === "") {
                callback(new Error(this.$t('Message.Required', {'name': this.$t('Disbursement.Request.Amount')})));
            } else {
                value = parseInt(value.replaceAll(',', ''));
                if(parseInt(this.maximumAmount) === 0 && parseInt(value) >= 0){
                  callback(new Error(this.$t('Notice.Message.NotEnoughBalance')));
                }
                else if (parseInt(value) > parseInt(this.maximumAmount)) {
                    callback(new Error(this.$t('Message.AmountMax', {'amountMax': this.showMoneyFormat(this.maximumAmount)})));
                }
                else if(parseInt(value) < parseInt(this.disbursementLimit.min)){
                  callback(new Error(this.$t('Message.AmountMinByLimit', {'amountMinByLimit': this.showMoneyFormat(this.disbursementLimit.min)})));
                }
                else {
                    callback();
                }
            }
        };
        return {
          title: 'Sidebar.MerchantRequestDisbursement',
          OtpConstant: OtpConstant,
          popupFeeVisible: false,
          breadcrumbs: [
              {
                  name: 'Title.Home',
                  routeName: 'Home',
                  active: true,
              },
              {
                  name: 'Sidebar.TransactionDisbursementManagement',
                  routeName: null,
                  active: true
              },
              {
                  name: 'Sidebar.MerchantRequestDisbursement',
                  routeName: 'MerchantRequestDisbursement',
                  active: true
              }
          ],
          formData: {
              bankNo: undefined,
              type: "0",
              accountNumberCardNumber: "",
              accountName: "",
              amount: "",
              content: "",
              code: ""
          },
          rules: {
              bankNo: [
                  {
                      required: true,
                      message: this.$t('Message.Required', {'name': this.$t('Disbursement.Request.Bank')}),
                      trigger: 'change'
                  }
              ],
              accountNumberCardNumber: [
                  {
                      // required: true,
                      // message: this.$t('Message.Required', {'name': this.$t('Disbursement.Request.AccountNumberCardNumber')}),
                      trigger: 'blur',
                      validator: checkCardNumber
                  }
              ],
              accountName: [
                  {
                      required: true,
                      message: this.$t('Message.Required', {'name': this.$t('Disbursement.Request.AccountName')}),
                      trigger: 'blur'
                  }
              ],
              amount: [
                  {
                      // required: true,
                      // message: this.$t('Message.Required', {'name': this.$t('Disbursement.Request.Amount')}),
                      trigger: 'blur',
                      validator: checkAmount
                  }
              ],

              content: [
                {
                  trigger: 'blur',
                  validator: checkContent
                }
              ],
          },
          listBank: [],
          checkTransfer: true,
          currencyMaskVND: currencyMaskVND,
          codeMask: codeMask,
          checkSubmit: false,
          visible: false,
          disbursementBalance: 0,
          collectionBalance: 0,
          isCheckAccount: false,
          loadingCheckAccount: false,
          isDisabledForm: false,
          google2FAErrorMessage: '',
          disbursementConfig: {
            paymentFee: [],
            processingFee: []
          },
          disbursementLimit: {
            max: 0,
            min: 0
          },
          maximumAmount: 0,
          minimumAmount: 0,
          isEnableAuth: false,
          fee: 0,
          totalAmount: 0,
          feeInfoByTotal: {
            percentageFee: 0,
            fixedCharge: 0
          },
          isVisibleModalSuccess: false,
          visibleModal: false,
          loading: false,
          orderCode: '',
          activeKey: "0",
          isByBatch: this.isset(this.$route.params.is_by_batch) ? this.$route.params.is_by_batch : 0,
          txtMessage: '',
          visibleModalRegister: false,
          popupRequestVisible: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'authentications/getUserLogin',
            merchant: 'authentications/getMerchants',
            userProfile: "user/getProfile",
            profileMerchant: "authentications/getProfileMerchant",
        }),
    },
    mounted() {
      if(this.isByBatch) {
        this.activeKey = "1";
      }

      if(!this.isEnableAuth) {
        this.popupRequestVisible = true;
      }

      window.addEventListener('storage', this.handleStorageEvent);
    },
    created() {
        CallApi.getListBank().then((response) => {
            this.listBank = response.data;
        });
        if (!isNaN(this.merchant)) {
          CallApi.getDisbursementExtraData(parseInt(this.merchant)).then((response) => {
            if (response.data){
              this.disbursementConfig.paymentFee = response.data.disbursement_fee_Config.payment_fee_config;
              this.disbursementConfig.processingFee = response.data.disbursement_fee_Config.process_fee_config;
              this.disbursementLimit.max = response.data.disbursement_limit.max ?? 0;
              this.disbursementLimit.min = response.data.disbursement_limit.min ?? 0;

              if(!this.popupRequestVisible) {
                //Check nếu chưa cấu hình phí thì bật popup chặn
                if(this.disbursementConfig.paymentFee.length == 0 && this.disbursementConfig.processingFee.length == 0) {
                  this.popupFeeVisible = true;
                }
              }

            }
            this.loadDataFirst();
          })
        }
    },
    methods: {
        isset: helper.isset,
        checkIsMasterMerchant: helper.checkIsMasterMerchant,
        showMoneyFormat: helper.showMoneyFormat,
        handleStorageEvent(event) {
          if (event.key === 'componentEvent') {
            const parsedEvent = JSON.parse(event.newValue);
            
            if(!parsedEvent.data.isEnableAuth) {
              this.popupRequestVisible = true;
            }
            
            this.visibleModalRegister = false;
          }
        },
        handleRedirectCancel() {
          this.$router.push({ name: 'Home' });
        },
        handleDataLoaded(data) {
          this.isEnableAuth = data.isEnableAuth;
        },
        closeAlertModal() {
            this.visibleModal = false;
        },
        checkActiveModuleByBatch(){
          if (typeof this.profileMerchant.merchant_profile_extra !== 'undefined' && this.profileMerchant.merchant_profile_extra.is_disbursement_by_batch == 1) {
            return true
          } else {
            return false
          }
        },
        handleChangeTabs(key) {
          this.activeKey = key;                                                                                                                            
        },
        filterOption(input, option) {
            return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
        },
        onlyAlphabetNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if  (!(keyCode > 47 && keyCode < 58) && // numeric (0-9)
                !(keyCode > 64 && keyCode < 91) && // upper alpha (A-Z)
                !(keyCode > 96 && keyCode < 123)) // lower alpha (a-z)
            {
                $event.preventDefault();
            }
        },
        checkAccount() {
            let isValid = true;
            this.loadingCheckAccount = true;
            this.$refs.ruleForm.validateField(['bankNo', 'accountNumberCardNumber'], (msg)=> {
                if (msg) {
                    isValid = false;
                  this.loadingCheckAccount = false;
                }
            });
            if (isValid) {
                let params = {
                    bank_no: this.formData.bankNo,
                    account_type: this.formData.type,
                    account_no: this.formData.accountNumberCardNumber,
                }
                CallApi.checkAccount(params).then((response) => {
                  this.loadingCheckAccount = false;
                    if (response.data) {
                      this.showNotice('success', this.$t('Notice.Message.ValidAccountInformation'), '');
                      this.formData.accountName = response.data.account_name;
                      this.checkTransfer = false;
                      this.isCheckAccount = true;
                      this.isDisabledForm = false;
                    } else {
                      this.loadingCheckAccount = false;
                      this.showCheckAccountError()
                    }
                }).catch(() => {
                  this.loadingCheckAccount = false;
                  this.showCheckAccountError()
                });
            }
          
        },
        showCheckAccountError(){
          this.handleDisplayCustomValidateErr('accountNumberCardNumber', this.$t('Disbursement.Request.InvalidAccount'));
          // this.showNotice('error', this.$t('Disbursement.Request.InvalidAccount'), '');
          this.checkTransfer = 'disabled';
          this.isDisabledForm = true;
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.showModalRegister()
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        handleSubmitOtp(){
          this.checkSubmit = true;
          CallApi.createRequestDisbursement(this.formData).then((response) => {
            if (response.code) {
              if (response.errors !== 'NotEnableGoogle2FA' && response.errors !== 'InvalidCodeGoogle2FA' && response.errors !== 'CreateRequestDisbursementFail' && response.errors !== 'InvalidBankCMS' && response.errors !== 'ExceedsTransactionLimit' && response.errors !== 'IsOffFeeByBatch' && response.errors !== 'NotEnableDisbursement') {
                this.showNotice('error', response.errors, '');
              }
              else if(response.errors === 'InvalidCodeGoogle2FA'){
                this.google2FAErrorMessage = this.$t('Disbursement.Request.IncorrectAuthenticationCode')
                this.handleDisplayCustomValidateErr('code', this.google2FAErrorMessage);
                // this.showNotice('error', this.$t('Disbursement.Request.IncorrectAuthenticationCode'), '');
              }
              else if(response.errors === 'IsOffFeeByBatch' || response.errors === 'NotEnableDisbursement') {
                this.txtMessage = this.$t('Disbursement.IsOffFeeByBatch');
                this.visibleModal = true;
                this.loading = false;
                this.checkSubmit = false;
                return false;
              }
              else {
                this.showNotice('error', this.$t('Notice.Message.' + response.errors), '');
              }
            } else {
              this.handleModalRegisterClose()
              this.success();
            }
            this.orderCode = response.data.order_code ?? '';
            this.loading = false;
            this.checkSubmit = false;
          }).catch(() => {
            this.loading = false;
            this.checkSubmit = false;
            this.showNotice('error', this.$t('Notice.Message.TransactionFail'), '');
          });
        },
        handleModalRegisterClose(){
          this.visibleModalRegister = false;
        },
        resetCheckAccount() {
            this.checkTransfer = true;
            this.formData.accountName = '';
            this.isDisabledForm = true;
        },
        showNotice(type, title, content) {
            this.$notification[type]({
                message: title,
                description: content,
                duration: 5,
                style: {
                    "margin-top": "50px"
                },
            });
        },
        success() {
            // const h = this;
            // this.$success({
            //     title: h.$t('Notice.Message.CreateRequestDisbursementSuccess'),
            //     okText: h.$t('Button.OK'),
            //     onOk() {
            //         h.$router.push({ name: 'TransactionDisbursementList' })
            //     }
            // });
          this.isVisibleModalSuccess = true;
        },
        loadDataFirst() {
            // set balance
            let maximumAmount = 0;
            this.$store.dispatch("dashboard/getBalanceMerchant", {"merchant_id": this.merchant})
            .then(response => {
                if (response.data) {
                    this.disbursementBalance = response.data.disbursement_balance;
                    this.collectionBalance = response.data.collection_balance;
                    if (parseInt(response.data.disbursement_balance) === 0){
                      this.maximumAmount = 0;
                      return;
                    }
                    maximumAmount = this.handleCalculateAmount(response.data.disbursement_balance);
                    if (parseInt(maximumAmount) > parseInt(this.disbursementLimit.max)){
                      this.maximumAmount = this.disbursementLimit.max;
                    }
                    else{
                      this.maximumAmount = maximumAmount > 0 ? maximumAmount : 0
                    }
                } else {
                    this.disbursementBalance = 'NaN';
                    this.collectionBalance = 'NaN';
                }
            });
        },
        handleCalculateAmount(amount){
          let fixedCharge = 0,
              percentageFee = 0,
              paymentFeeMax = this.disbursementConfig.paymentFee.fee_value_max,
              paymentFeeMin = this.disbursementConfig.paymentFee.fee_value_min,
              processingFeeMax = this.disbursementConfig.processingFee.fee_value_max,
              processingFeeMin = this.disbursementConfig.processingFee.fee_value_min;
          if (this.disbursementConfig.paymentFee.rate_type === 1){
            ({ fixedCharge, percentageFee } = this.handleFee(amount, paymentFeeMax, paymentFeeMin, percentageFee, fixedCharge, parseFloat(this.disbursementConfig.paymentFee.fee_value)));
          }else{
            fixedCharge = fixedCharge + parseFloat(this.disbursementConfig.paymentFee.fee_value ?? 0);
          }

          if (this.disbursementConfig.processingFee.rate_type === 1){
            ({ fixedCharge, percentageFee } = this.handleFee(amount, processingFeeMax, processingFeeMin, percentageFee, fixedCharge, parseFloat(this.disbursementConfig.processingFee.fee_value)));
          }else{
            fixedCharge = fixedCharge + parseFloat(this.disbursementConfig.processingFee.fee_value ?? 0);
          }
          // this.maximumAmount = Math.floor((amount - fixedCharge)/(percentageFee + 1));
          return Math.floor((amount - fixedCharge)/(percentageFee + 1));
        },
        handleFee(amount, max, min, percentageFee, fixedCharge, value){
          let checkFee = amount*value/100;
          if (this.isset(max) && checkFee > max){
            fixedCharge = fixedCharge + max;
          }
          else if(this.isset(min) && checkFee < min){
            fixedCharge = fixedCharge + min;
          }
          else{
            percentageFee = percentageFee + value/100;
          }
          return { fixedCharge, percentageFee };
        },
        handleTotalAmount(){
          if (!this.isDisabledForm){
            this.formData.amount = this.showMoneyFormat(this.maximumAmount)
          }
        },
        handleAmount(value){
          let fixedCharge = 0,
              percentageFee = 0,
              paymentFeeMax = this.disbursementConfig.paymentFee.fee_value_max,
              paymentFeeMin = this.disbursementConfig.paymentFee.fee_value_min,
              processingFeeMax = this.disbursementConfig.processingFee.fee_value_max,
              processingFeeMin = this.disbursementConfig.processingFee.fee_value_min,
              totalAmount = 0;
          value = value !== '' ? value.replaceAll(',', '') : 0;
          if (parseInt(value) === 0 || parseInt(this.disbursementBalance) === 0){
            this.fee = 0;
            this.totalAmount = 0
            return;
          }

          if (this.disbursementConfig.paymentFee.rate_type === 1){
            ({ fixedCharge, percentageFee } = this.handleFee(value, paymentFeeMax, paymentFeeMin, percentageFee, fixedCharge, parseFloat(this.disbursementConfig.paymentFee.fee_value)));
          }
          else{
            fixedCharge = fixedCharge + parseFloat(this.disbursementConfig.paymentFee.fee_value ?? 0);
          }
          if (this.disbursementConfig.processingFee.rate_type === 1){
            ({ fixedCharge, percentageFee } = this.handleFee(value, processingFeeMax, processingFeeMin, percentageFee, fixedCharge, parseFloat(this.disbursementConfig.processingFee.fee_value)));
          }
          else{
            fixedCharge = fixedCharge + parseFloat(this.disbursementConfig.processingFee.fee_value ?? 0);
          }
          // console.log('percentageFee', percentageFee)
          // console.log('fixedCharge', fixedCharge)
          totalAmount = parseFloat(value) + Math.floor(parseFloat(value*percentageFee)) + parseFloat(fixedCharge);
          this.fee = Math.round(parseFloat(value*percentageFee)) + fixedCharge;
          this.totalAmount = Math.floor(totalAmount)
        },
        handleClickViewTransaction(){
          this.$router.push({ name: 'TransactionDisbursementDetail', params: {order_code: this.orderCode}})
        },
        reloadPage() {
          window.location.reload();
        },
        test(){
          this.isVisibleModalSuccess = true;
        },
        handleDisplayCustomValidateErr(fieldName, message){
          let fields = this.$refs.ruleForm.fields;
          fields.filter(field => field.prop === fieldName).forEach(field => {
            field.validateMessage = message
            field.validateState = 'error'
          });
        },
        handlePaste(event) {
          // Prevent the default paste behavior
          event.preventDefault()

          // Get the pasted text from the clipboard
          let pastedData = event.clipboardData.getData('text/plain')

          // Clean and format the pasted data
          let cleanedData = this.cleanAndFormatData(pastedData)

          // Get selection start and end
          let selectionStart = event.target.selectionStart
          let selectionEnd = event.target.selectionEnd

          // Combine existing text with new pasted text
          let textBefore = this.formData.content.slice(0, selectionStart)
          let textAfter = this.formData.content.slice(selectionEnd)
          this.formData.content = textBefore + cleanedData + textAfter

          // Move cursor to end of pasted text
          this.$nextTick(() => {
            let newCursorPos = selectionStart + cleanedData.length
            event.target.selectionStart = event.target.selectionEnd = newCursorPos
          })

        },
        cleanAndFormatData(data) {
          // Remove any null characters
          data = data.replace(/\0/g, '')

          // Replace all types of line breaks with a standard line break
          data = data.replace(/\r\n|\r|\n/g, '\n')

          // Remove extra line breaks (more than two consecutive)
          data = data.replace(/\n{3,}/g, '\n\n')

          // Remove leading/trailing whitespace from each line
          data = data.split('\n').map(line => line.trim()).join('\n')

          // Remove extra spaces between words
          data = data.replace(/\s+/g, ' ')

          // Remove any remaining control characters
          data = data.replace(/[^\x20-\x7E\s]/g, '')

          // Remove any leftover Office-specific artifacts (examples)
          data = data.replace(/\[Edit\]/g, '')
          data = data.replace(/^Formatted: .+$/gm, '')
          data = data.replace(/^Style: .+$/gm, '')

          return data.trim()
        },
        showModalRegister() {
          this.visibleModalRegister = true;
        },
    },
    beforeMount() {
    },
    watch:{
      'formData.type': function() {
        this.formData.accountNumberCardNumber = ''
        this.checkTransfer = true;
        this.formData.accountName = '';
        this.isDisabledForm = true;
      },
      'formData.amount': function (){
        this.handleAmount(this.formData.amount)
      }
    }
}
</script>

<style>
.box-request__disbursement .font-svn__poppins__medium{
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85)!important;
}
.box-request__disbursement .ant-form-item .ant-form-item-label{
  margin-bottom: 0 !important;
}
  .disbursement-balance{
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
    .account-name:disabled {
      color: rgba(0, 0, 0, 0.85)!important;
        background-color: #f5f5f5 !important;
    }
    .disbursement-money{
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
    .disbursement-money div{
        min-width: 150px;
    }
    .disbursement-money:first-child{
        margin-top: 0;
    }
    .disbursement-body .ant-form-vertical .ant-form-item{
        margin-bottom: 15px;
    }
    .disbursement-body .ant-form-explain{
        margin-top: 8px!important;
    }
    .disbursement-body .ant-tabs-tab{
        vertical-align: text-bottom;
        font-size: 14px;
    }
    .disbursement-body .ant-upload-text,
    .disbursement-body .ant-upload.ant-upload-drag p.ant-upload-drag-icon{
        margin-bottom: 0!important;
    }
    .disbursement-body .ant-upload-text{
        margin-left: 10px!important;
        font-size: 13px!important;
    }
    .disbursement-body .icon-download{
        font-size: 18px;
        vertical-align: middle;
    }
    .disbursement-body .icon-drag{
        color: #1890ff!important;
    }
    .disbursement-body .ant-upload-list-item-name{
        vertical-align: text-bottom;
    }
    .alert-icon__warning{
        color: #faad14!important;
        line-height: 1;
    }
    .disbursement-body .alert-icon > i{
        font-size: 50px;
    }
  .form-data-type .ant-row{
    padding-bottom: 0 !important;
  }
  .first-wf{
    padding-bottom: 0 !important;
  }
  .check-account-btn{
    display: flex;
    align-items: center;
  }
  .title-note{
    font-weight: 600;
  }
  .spinner-border{
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  .text-only-block{
    display: flex !important;
    padding-bottom: 0 !important;
  }
  .disabled-span-btn{
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed !important;
  }
  .custom-label{
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px
  }
  .custom-section{
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .disbursement-body .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }
  .disbursement-body .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab.ant-tabs-tab-active{
    border: 1px solid #e8e8e8;
    border-bottom: 1px solid #fff;
    color: #1589F7;
  }
  .disbursement-body .ant-form-item{
    color: rgba(0, 0, 0, 0.85);
  }
</style>
  
